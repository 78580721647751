import * as React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import Activation from "../../components/activation";
import Feature from "../../components/Feature";
import FeaturesSummary from "../../components/productPage/featuresSummary";
import Layout from "../../components/layout";
import { container } from "./mobile-check-in.module.css";
import { SEO } from "../../components/seo";

const MobileCheckInPage = ({ data }) => {
  return (
    <Layout pageTitle="Mobile Check-in and Out">
      <Container fluid className={container}>
        <Feature
          title="Dock & Depart with Ease: Mobile Check-In/Out"
          subtitle="Revolutionize your check-in and check-out process with Floatist’s Mobile Check-In & Check-Out feature, designed to make every charter seamless and stress-free. Our mobile-friendly solution allows guests to complete check-in tasks directly from their smartphones, where they can engage with detailed equipment instructions at their own pace—leading to better retention and understanding compared to traditional face-to-face briefings. "
          imageData={data.mobileCheckin.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Images, videos and multi-language instructions"
          subtitle="Floatist sets up your checklists for you with images and videos, providing guests with clear visual instructions. Additionally, multi-language support ensures that every guest receives understandable and accessible information, setting the stage for a smooth and welcoming start to their charter."
          imageData={data.imagesInstructions.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title="Support chat functionality"
          subtitle="Floatist facilitates direct and instant messaging between charter guests and charter companies, ensuring that any questions or needs can be addressed effectively. This feature not only boosts guest confidence by providing accessible, real-time support but also streamlines the communication process, making it easier for charter companies to deliver exceptional service. "
          imageData={data.support.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Create tasks from check-out"
          subtitle="If any issues or damages are identified, staff can instantly create and assign tasks directly from the check-out interface. This immediate action ensures that maintenance or repair needs are promptly addressed, preventing any oversight and improving turnaround times for next charters. By empowering your team to manage and track repairs in real time, Floatist helps maintain the highest standards of yacht readiness and guest satisfaction."
          imageData={data.easyTaskCreation.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title="Optimize check-in staff and technicians' workflows"
          subtitle="Technicians have their hands free and can instead assist customers where needed, answering client questions during finalizing check-in"
          imageData={data.submittedList.childImageSharp.gatsbyImageData}
          textSide="right"
        />
      </Container>
      <FeaturesSummary />
      <Activation title="Take the lead" buttonText="Request a Demo" />
    </Layout>
  );
};

export default MobileCheckInPage;

export const Head = () => (
  <SEO
    description="Revolutionize your check-in and check-out process with
    Floatist’s Mobile Check-In & Check-Out feature, designed to make every
    charter seamless and stress-free. Our mobile-friendly solution allows guests
    to complete check-in tasks directly from their smartphones, where they can
    engage with detailed equipment instructions at their own pace—leading to
    better retention and understanding compared to traditional face-to-face
    briefings."
  />
);

export const query = graphql`
  query {
    file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 400)
      }
    }
    support: file(relativePath: { eq: "support.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    easyTaskCreation: file(relativePath: { eq: "easy-task-creation.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    submittedList: file(relativePath: { eq: "submitted-list.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imagesInstructions: file(relativePath: { eq: "images-instructions.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    mobileCheckin: file(relativePath: { eq: "checkin.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
